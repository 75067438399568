import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Transition } from 'react-spring'
import styled from 'styled-components'
import media from 'styled-media-query'

const SectionWrapper = styled.section`
  text-align: center;
  position: relative;
  margin: 60px 0;
`

const BannerTitle = styled.h3`
  font-size: 3em;
  color: ${props =>
    props.tech ? 'var(--coffee-white)' : 'var(--coffee-black)'};
  ${media.lessThan('medium')`
  font-size: 2em;
  `};
`

const BannerSection = ({ tech }) => (
  <StaticQuery
    query={graphql`
      query BannerQuery {
        allBannerJson {
          edges {
            node {
              main {
                message
              }
              tech {
                message
              }
            }
          }
        }
      }
    `}
    render={data => {
      const content = data.allBannerJson.edges[0].node
      return (
        <SectionWrapper>
          <BannerTitle tech={tech}>
            {tech ? content.tech.message : content.main.message}
          </BannerTitle>
        </SectionWrapper>
      )
    }}
  />
)

BannerSection.propTypes = {
  tech: PropTypes.bool.isRequired,
}

export default BannerSection
