import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import coffeeSpacePic from '../images/main/hotblack-coffee-space.jpg'
import floorPlanPic from '../images/main/hotblack-tech-floorplan.png'

const SectionWrapper = styled.section`
  display: grid;
  min-height: calc(100vh - 48px);
  max-height: 100vh;
  padding-top: 48px;
  margin-top: 7.5em;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, 1fr);
  ${media.lessThan('medium')`
    margin-top: 0;
    max-height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 250px 1fr; 
  `};
`

const EventsInfo = styled.div`
  padding: 3em 4em;
  margin: -1em 0 0 0;
  display: grid;
  grid-column: 2 / 10;
  grid-row: 1/2;
  z-index: 3;
  background: var(--coffee-white);
  border-bottom: 1px solid var(--flirt-alert);
  ${media.lessThan('medium')`
    grid-column: 1 / -1;
    grid-row: 2/-1;
    padding: 5%;
  `};
`

const EventsTitle = styled.h3`
  font-size: 2em;
  margin: 0;
  margin-bottom: 0.5em;
`

const EventsCopy = styled.p`
  font-size: 1.5em;
  margin: 0;
`

const EventsLink = styled.p`
  margin: 3em 0;
  margin-left: auto;
  a {
    color: var(--coffee-white);
    width: 5em;
    padding: 1em 2em;
    background: var(--flirt-alert);
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0 3px 6px rgba(#000, 0.16), 0 3px 6px rgba(#000, 0.23);
  }
  ${media.lessThan('medium')`
    margin-right: auto;
  `};
`

const EventsImage = styled.div`
  overflow: hidden;
  margin-top: 2em;
  z-index: 1;
  display: flex;
  max-height: 100vh;
  grid-row: ${props => (props.tech ? '2 /-1' : '1 /-1')};
  grid-column: ${props => (props.tech ? '2 /-1' : '2 /-1')};
  img {
    width: 100%;
    object-fit: cover;
    ${media.lessThan('medium')`
      margin-top: auto;
    `};
  }
  ${media.lessThan('medium')`
    padding: 0 5%;
    margin-top: 0;
    grid-column: 1 / -1;
    grid-row: 1/2;
  img {
    min-height:250px;
    margin-top: 0;
  }
  `};
`

const EventsSection = ({ tech }) => (
  <StaticQuery
    query={graphql`
      query EventQuery {
        allEventJson {
          edges {
            node {
              title
              main {
                message
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              tech {
                message
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const title = data.allEventJson.edges[0].node.title
      const content = data.allEventJson.edges[0].node
      let current = tech ? content.tech : content.main
      return (
        <SectionWrapper id="events">
          <EventsInfo>
            <EventsTitle>{title}</EventsTitle>
            <EventsCopy>{current.message}</EventsCopy>
            <EventsLink tech={tech}>
              <a href="./events/">More info</a>
            </EventsLink>
          </EventsInfo>
          <EventsImage tech={tech}>
            <img src={current.image.childImageSharp.fluid.src} alt={title} />
          </EventsImage>
        </SectionWrapper>
      )
    }}
  />
)

EventsSection.propTypes = {
  tech: PropTypes.bool.isRequired,
}

export default EventsSection
