import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import uniqid from 'uniqid'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import aboutPic1 from '../images/main/hotblack-coffee-about1.png'
import aboutPic2 from '../images/main/hotblack-coffee-about2.png'
import aboutPic3 from '../images/main/hotblack-coffee-about3.png'

import techPic1 from '../images/main/hotblack-tech-about1.svg'
import techPic2 from '../images/main/hotblack-tech-about2.svg'
import techPic3 from '../images/main/hotblack-tech-about3.svg'
import techPic4 from '../images/main/hotblack-tech-about4.svg'

const SectionWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 48px;
  padding-top: 48px;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 36px;
`

const AboutInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'text image';
  &:nth-of-type(2n + 2) {
    grid-template-areas: 'image text';
  }
  ${media.lessThan('medium')`
  margin: 18px 0;
  grid-template-columns: 1fr;
    grid-template-areas: 'image' 'text';
  &:nth-of-type(2n + 2) {
    grid-template-areas: 'image' 'text';
  }
  `};
`

const AboutText = styled.div`
  grid-area: text;
  padding: 5em 3em 0 7em;
  ${media.lessThan('medium')`
  padding: 0;
  `};
`
const AboutContentWrapper = styled.div`
  h3 {
    font-size: ${props => (props.tech ? '2em' : '2.5em')};
    color: ${props =>
      props.tech ? 'var(--coffee-white)' : 'var(--coffee-black)'};
  }
  p {
    font-size: ${props => (props.tech ? '1.25em' : '1.5em')};
    color: ${props =>
      props.tech ? 'var(--coffee-white)' : 'var(--coffee-black)'};
  }
  a {
    background: ${props => (props.tech ? 'var(--coffee-white)' : undefined)};
    color: ${props => (props.tech ? 'var(--flirt-alert)' : undefined)};
    padding: 2px;
    text-decoration: none;
    transition: all 0.5s;
    &:hover {
      color: var(--coffee-black);
    }
  }
`

const AboutImage = styled.figure`
  grid-area: image;
  ::after {
    box-shadow: 0 20px 70px -10px rgba(51, 51, 51, 0.7),
      0 50px 100px 0 rgba(51, 51, 51, 0.2);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const AboutContent = ({ title, text, image }) => (
  <AboutInfo>
    <AboutText>
      <h3>{title}</h3>
      <div>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </AboutText>
    <AboutImage>
      <img src={image} alt={title} />
    </AboutImage>
  </AboutInfo>
)

AboutContent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}

const AboutSection = ({ tech }) => (
  <StaticQuery
    query={graphql`
      query AboutQuery {
        allAboutJson {
          edges {
            node {
              main {
                title
                text
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              tech {
                title
                text
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const content = data.allAboutJson.edges[0].node
      let current = tech ? (current = content.tech) : (current = content.main)
      return (
        <SectionWrapper id="about">
          <AboutContentWrapper tech={tech}>
            {current.map(ctx => {
              let paragraph = ctx.text.replace(/\n/g, '<br /><br />')
              return (
                <AboutContent
                  key={uniqid()}
                  title={ctx.title}
                  text={paragraph}
                  image={ctx.image.childImageSharp.fluid.src}
                />
              )
            })}
          </AboutContentWrapper>
        </SectionWrapper>
      )
    }}
  />
)

AboutSection.propTypes = {
  tech: PropTypes.bool.isRequired,
}

export default AboutSection
