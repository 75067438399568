import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import uniqid from 'uniqid'
import styled from 'styled-components'
import media from 'styled-media-query'

const SectionWrapper = styled.section`
  display: grid;
  padding: 0 calc(5% + 7em) 0;
  border-top: 1px solid var(--flirt-alert);
  border-bottom: 1px solid var(--flirt-alert);
  margin: 4em 0 2em;
  ${media.lessThan('medium')`
    padding: 5%;
  `};
`

const Title = styled.h3`
  font-size: 3em;
  color: var(--flirt-alert);
  ${media.lessThan('medium')`
    font-size: 2.75em;
  `};
`
const SponsorList = styled.ul`
  padding-left: 0;
  padding-bottom: 4em;
`

const Sponsor = styled.li`
  font-size: 2em;
  list-style: none;
  margin: 8px 0;
  ${media.lessThan('medium')`
    font-size: 1.5em;
  `};
`

const CharitySection = ({ tech }) => (
  <StaticQuery
    query={graphql`
      query CharityQuery {
        allCharityJson {
          edges {
            node {
              title
              sponsors
            }
          }
        }
      }
    `}
    render={data => {
      const content = data.allCharityJson.edges[0].node
      if (!tech) {
        return (
          <SectionWrapper id="charity">
            <Title>{content.title}</Title>
            <SponsorList>
              {content.sponsors.map(sponsor => (
                <Sponsor key={uniqid()}>{sponsor}</Sponsor>
              ))}
            </SponsorList>
          </SectionWrapper>
        )
      }
    }}
  />
)

CharitySection.propTypes = {
  tech: PropTypes.bool.isRequired,
}

export default CharitySection
