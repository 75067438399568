import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Transition } from 'react-spring'
import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'
import Confetti from 'react-dom-confetti'
import posed from 'react-pose'
import uniqid from 'uniqid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import landingPeople from '../images/main/hotblack-coffee-hero.jpg'
import landingCoffee from '../images/main/hotblack-tech-illustration.svg'
import hbJingle from '../images/hotblack-jingle.mp4'
import hbStore from '../images/hotblack-store.mp4'

const config = {
  angle: 90,
  spread: 300,
  startVelocity: 80,
  elementCount: 325,
  decay: 0.9,
}

const Fade = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

const Pop = posed.div({
  from: {
    y: 100,
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
  to: {
    y: -15,
    opacity: 1,
    transition: {
      duration: 1500,
    },
  },
})

const HeaderWrapper = styled.header`
  height: calc(100vh - 110px);
  display: grid;
  padding-left: 2.5%;
  padding-right: 2.5%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'hero'
    'store';
  ${media.lessThan('medium')`
    height: 75vh;
  `};
`

const Player = styled(Fade)`
  margin: 0;
  grid-area: hero;
  position: relative;
  overflow: hidden;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
    object-position: center bottom;
    object-fit: ${props => (props.tech ? 'contain' : 'cover')};
    ${media.lessThan('medium')`
    object-fit: cover;
  `};
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`
const StoreInfo = styled.div`
  grid-area: store;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  h5 {
    color: ${props =>
      props.tech ? 'var(--coffee-white)' : 'var(--coffee-black)'};
    margin: 16px auto 24px;
  }
  a {
    color: ${props =>
      props.tech ? 'var(--coffee-white)' : 'var(--coffee-black)'};
    text-decoration: none;
  }
  ${media.lessThan('medium')`
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
    h5 {
      margin: 0; 
    }
  `};
`

const StoreInfoDetail = styled.div`
  margin: auto;
  font-size: 1.5em;
  text-align: center;
  ${media.lessThan('medium')`
    font-size: 1.25em;
    text-align: left;
    margin-bottom: 0;
    margin-left: 0;
    &:nth-of-type(2) {
      order: 1;
      margin-top: 4px;
   
    &:nth-of-type(3) {
      margin: auto;
      margin-right: 0;
      margin-top: 4px;
      order: 2;
    }
    &:nth-of-type(4) {
      margin: 0;
      margin-top: auto;
      margin-left: auto;
    }
  `};
`

const ConfettiWrapper = styled.div`
  z-index: 99999;
  display: flex;
  div {
    margin: auto;
  }
`
const bounce = keyframes`
  from {
    transform: translateX(5px)
  }
  to {
    transform: translateX(-5px)
  }
`

const StoreCTA = styled(Pop)`
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: center;
  div {
    display: flex;
    margin-left: auto;
    margin-right: 15px;
    max-width: 400px;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.5s;
    h3,
    span {
      color: white;
      font-size: 1.25rem;
      color: black;
    }
    h3 {
      margin: 0;
      padding: 12px 20px;
      text-transform: Capitalize;
    }
    span {
      margin: auto;
      font-size: 1.25rem;
      margin-right: 20px;
      svg {
        transform-origin: center;
      }
    }
    &:hover {
      background: rgba(255, 255, 255, 1);
      transition: all 0.5s;
      span {
        color: #c11515;
      }
      svg {
        animation: none;
        transform: rotate(0deg);
        animation: ${bounce} 0.5s alternate infinite;
      }
    }
  }
`

const Landing = ({ jingle, tech }) => {
  let [confetti, setConfetti] = useState(false)
  let [cta, setCTA] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        query LandingQuery {
          allLandingJson {
            edges {
              node {
                address
                phone
                email
                googleMap
                weekday
                weekend
              }
            }
          }
        }
      `}
      render={data => {
        const content = data.allLandingJson.edges[0].node
        const address = content.address.replace(/\n/g, '<br />')
        const confettiOn = () => {
          setConfetti(true)
          setTimeout(() => {
            setCTA(true)
          }, 1000)
        }
        const confettiOff = () => {
          setConfetti(false)
          setCTA(false)
        }
        return (
          <HeaderWrapper id="landing">
            <ConfettiWrapper>
              <Confetti active={confetti} config={config} />
            </ConfettiWrapper>
            <Player tech={tech}>
              {!jingle &&
                !tech && (
                  <>
                    <video
                      onEnded={confettiOn}
                      onPlay={confettiOff}
                      muted
                      autoPlay
                    >
                      <source src={hbStore} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <a href="https://store.hotblack-coffee.com">
                      <StoreCTA pose={cta ? 'to' : 'from'}>
                        <div>
                          <h3>Buy the Best. Coffee. Ever.</h3>
                          <span>
                            <FontAwesomeIcon icon={faArrowRight} />
                          </span>
                        </div>
                      </StoreCTA>
                    </a>
                  </>
                )}
              {jingle &&
                !tech && (
                  <video controls autoPlay>
                    <source src={hbJingle} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              {tech && <img src={landingCoffee} alt="HotBlack Coffee" />}
            </Player>
            <StoreInfo tech={tech}>
              <StoreInfoDetail>
                <h5>
                  <a
                    href={content.googlaMap}
                    dangerouslySetInnerHTML={{
                      __html: address,
                    }}
                  />
                </h5>
              </StoreInfoDetail>
              <StoreInfoDetail>
                <h5>{content.phone}</h5>
              </StoreInfoDetail>
              <StoreInfoDetail>
                <h5>
                  <a href={`mailto: ${content.email}`}>{content.email}</a>
                </h5>
              </StoreInfoDetail>
              <StoreInfoDetail>
                <h5>
                  {content.weekday}
                  <br />
                  {content.weekend}
                </h5>
              </StoreInfoDetail>
            </StoreInfo>
          </HeaderWrapper>
        )
      }}
    />
  )
}
Landing.propTypes = {
  tech: PropTypes.bool.isRequired,
  jingle: PropTypes.bool.isRequired,
}

export default Landing
