import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import uniqid from 'uniqid'
import PropTypes from 'prop-types'
import Tilt from 'react-tilt'
import styled from 'styled-components'
import media from 'styled-media-query'

const SectionWrapper = styled.section`
  padding: 0 calc(7% + 5em);
  padding-top: 48px;
  margin: 8em 0 6em;
  ${media.lessThan('medium')`
    margin-top: 0;
    padding: 0 5%;
  `};
`

const Title = styled.h3`
  font-size: 3em;
  text-align: center;
  transform: skew(-5deg);
`

const PressItemList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &::after {
    content: '';
    flex:auto;
  }
`

const PressItem = styled.div`
  width: calc(100% / 4 - 32px);
  padding: 0 16px;
  margin: auto;
  text-align: center;
  img {
    height: 100%;
    width: 100%;
    max-height: 180px;
    max-width: 180px;
    object-fit: contain;
  }
  ${media.lessThan('medium')`
  width: calc(100% / 3 - 32px);
  `};
`

const MorePressItemList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &::after {
    content: '';
    flex:auto;
  }
`

const MorePressItem = styled.div`
  width: calc(100% / 7 - 32px);
  padding: 0 16px;
  margin: auto;
  text-align: center;
  img {
    height: 100%;
    width: 100%;
    max-height: 180px;
    max-width: 180px;
    object-fit: contain;
  }
  ${media.lessThan('medium')`
    width: calc(100% / 3 - 32px);
  `};
`

const Press = props => (
  <PressItem>
    <Tilt className="Tilt" options={{ max: 25 }}>
      <a href={props.url} target="_blank" rel="noopener noreferrer">
        <img src={props.image} alt={props.name} />
      </a>
    </Tilt>
  </PressItem>
)

const MorePress = props => (
  <MorePressItem>
    <Tilt className="Tilt" options={{ max: 25 }}>
      <img src={props.image} alt={props.name} />
    </Tilt>
  </MorePressItem>
)

const PressSection = ({ tech }) => (
  <StaticQuery
    query={graphql`
      query PressQuery {
        allPressJson {
          edges {
            node {
              featured {
                name
                url
                image {
                  childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 250) {
                      src
                    }
                  }
                }
              }
              extra {
                name
                image {
                  childImageSharp {
                    fluid(maxWidth: 200, maxHeight: 200) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const featured = data.allPressJson.edges[0].node.featured
      const extra = data.allPressJson.edges[0].node.extra
      if (!tech) {
        return (
          <SectionWrapper id="press">
            <Title>As seen on</Title>
            <PressItemList>
              {featured.map(ctx => (
                <Press
                  key={uniqid()}
                  name={ctx.name}
                  url={ctx.url}
                  image={ctx.image.childImageSharp.fluid.src}
                />
              ))}
            </PressItemList>
            <Title>and more</Title>
            <MorePressItemList>
              {extra.map(ctx => (
                <MorePress
                  key={uniqid()}
                  image={ctx.image.childImageSharp.fluid.src}
                  name={ctx.name}
                />
              ))}
            </MorePressItemList>
          </SectionWrapper>
        )
      }
    }}
  />
)

PressSection.propTypes = {
  tech: PropTypes.bool.isRequired,
}

export default PressSection
